import React from "react"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import PricesLayout from "../components/Pricespage/PricesLayout/PricesLayout"
import Helmet from "react-helmet"

const Prices = () => {
  return (
    <>
      <Helmet>
        <title>Árak - Jakab-Jánosi Eszter, érdi pszichológus</title>
        <link
          rel="alternate"
          media="only screen and (max-width: 1000px)"
          href="https://jakabjanosieszter.hu/arakmobil"
        ></link>
      </Helmet>
      <Header />
      <PricesLayout />
      <Footer />
    </>
  )
}

export default Prices
